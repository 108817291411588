import { initializeConnector } from '@web3-react/core';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { MetaMask } from '@web3-react/metamask';

const mainChains = [5];
const secondaryChains = [1, 5, 56, 97, 137, 80001];

export const [metamaskInjector, metamaskHooks] = initializeConnector(actions => new MetaMask({ actions }));

export const [walletConnectV2, walletConnectV2Hooks] = initializeConnector(
  actions =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: process.env.REACT_APP_PROJECT_ID,
        chains: mainChains,
        optionalChains: secondaryChains,
        showQrModal: true,
      },
    }),
);
