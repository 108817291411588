const CHAIN_LIST = {
  ETH_MAINNET: 1,
  ETH_TESTNET: 5,
  KLAY_MAINNET: 8217,
  KLAY_TESTNET: 1001,
  POLYGON_MAINNET: 137,
  POLYGON_TESTNET: 80001,
  BSC_MAINNET: 56,
  BSC_TESTNET: 97,
};

export default CHAIN_LIST;
