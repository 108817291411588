import { useEffect } from 'react';
import { getItem, removeItem, STORAGE_SESSION, GET_INFO } from 'utils/sessionStorage';
import useAuth from './useAuth';
import useSignIn from 'pages/Connect/Controller/useSignIn';

const useEagerConnect = () => {
  const { metamaskConnect, kaikasConnect } = useAuth();

  // 잠시 klip test하느라 꺼둠

  // useSignIn();

  useEffect(() => {
    const connectorId = getItem(STORAGE_SESSION);
    if (!connectorId) return;

    if (connectorId === 'metamask') {
      // removeItem(GET_INFO);
      metamaskConnect();
    }
    if (connectorId === 'kaikas') {
      // removeItem(GET_INFO);
      kaikasConnect();
    }
    // if (connectorId === 'klip') {
    //   kaikasConnect();
    // }
  }, [metamaskConnect, kaikasConnect]);
};

export default useEagerConnect;
