import React, { Suspense, useEffect, lazy } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import Switchs from 'Switchs';
import Loading from 'components/Loading';
import useEagerConnect from 'hooks/useEagerConnect';
import useAuth from 'hooks/useAuth';
const SignIn = lazy(() => import('pages/Connect/Login/index'));
const SignUp = lazy(() => import('pages/Connect/Register/index'));

const Routers = () => {
  const location = useLocation();
  const { account, chainId } = useWeb3React();
  const klayAccount = window?.klaytn?.selectedAddress;
  const { observingWallet } = useAuth();
  useEagerConnect();

  useEffect(() => {
    observingWallet();
  }, [account, chainId, klayAccount, observingWallet]);

  useEffect(() => {
    if (location.pathname !== '/list') document.body.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [location]);

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/*" element={<Switchs />} />
        <Route path="/connect" element={<SignIn />} />
        <Route path="/connect/register" element={<SignUp />} />
      </Routes>
    </Suspense>
  );
};

export default Routers;
