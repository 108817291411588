import { useCallback, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { useWeb3React } from '@web3-react/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { prepare } from 'klip-sdk';
import { signAtom } from 'atoms/state';
import { ERROR_MESSAGES_HANDLER } from 'constants/errorMessage';
import { connectorsByName } from 'utils/web3React';
import { toastError } from 'utils/toast';
import { setupETHNetwork } from 'utils/wallet';
import { setItem, getItem, removeItem, STORAGE_SESSION, WALLET_SESSION, GET_INFO } from 'utils/sessionStorage';
import getDeepLink from 'utils/detectDevice';
import CHAIN_LIST from 'constants/chain';
import { WALLET_TYPE, CHAIN } from 'constants/USER_INFO';
import { metamaskInjector, walletConnectV2 } from 'utils/connectors';
import fetchData from 'utils/fetchData';
import { requestUrl } from 'config';

const useAuth = () => {
  const { METAMASK, KAIKAS, KLIP } = WALLET_TYPE;
  const { KLAYTN, ETHEREUM, POLYGON, BSC } = CHAIN;
  const { PROVIDER_ERROR, CONNECTOR_CONFIG, KLAYTN_UNSUPPORTED_CHAIN } = ERROR_MESSAGES_HANDLER;
  const { ETH_MAINNET, ETH_TESTNET, KLAY_MAINNET, KLAY_TESTNET, POLYGON_MAINNET, POLYGON_TESTNET, BSC_MAINNET, BSC_TESTNET } = CHAIN_LIST;
  const err = useRef();
  const setSignedMessage = useSetRecoilState(signAtom);
  const { account, chainId } = useWeb3React();
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const walletType = getItem(STORAGE_SESSION);
  const isWalletConnect = getItem(WALLET_SESSION);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (isWalletConnect) {
      const { signIn } = requestUrl;
      const data = await fetchData(`${signIn}${account}`);
      const chain =
        chainId === KLAY_MAINNET || chainId === KLAY_TESTNET
          ? KLAYTN
          : chainId === POLYGON_MAINNET || chainId === POLYGON_TESTNET
            ? POLYGON
            : chainId === BSC_MAINNET || chainId === BSC_TESTNET
              ? BSC
              : ETHEREUM;
      if (account && !data) {
        navigate('/connect/register');
      }
      if (data) {
        const { member_username, member_wallet_type, member_imageurl, member_is_admin, member_network_names } = data;
        const isMember = member_network_names?.includes(chain);
        setItem(GET_INFO, {
          chain,
          chainId,
          account,
          username: member_username,
          walletType: member_wallet_type,
          profileImg: member_imageurl,
          admin: member_is_admin && isMember && 1,
        });
      }
    }
  }, [BSC, BSC_MAINNET, BSC_TESTNET, ETHEREUM, KLAYTN, KLAY_MAINNET, KLAY_TESTNET, POLYGON, POLYGON_MAINNET, POLYGON_TESTNET, account, chainId, isWalletConnect, navigate]);

  //메타마스크 로그인
  const metamaskConnect = useCallback(
    async chainId => {
      const connector = connectorsByName[METAMASK];
      // connector 체크
      if (!connector) return toastError(CONNECTOR_CONFIG);
      if ((window?.ethereum?.networkVersion === KLAY_MAINNET || window?.ethereum?.networkVersion === KLAY_TESTNET || !window.ethereum) && walletType !== METAMASK && !isWalletConnect) {
        window.open('https://metamask.io/download/', '_blank');
        return toastError(PROVIDER_ERROR);
      }
      // 로그인
      if (!isWalletConnect) {
        await metamaskInjector.activate(chainId);
        return setItem(STORAGE_SESSION, METAMASK);
      }
    },
    [isWalletConnect, walletType, METAMASK, CONNECTOR_CONFIG, KLAY_MAINNET, KLAY_TESTNET, PROVIDER_ERROR],
  );

  //카이카스 로그인
  const kaikasConnect = useCallback(async () => {
    const connector = connectorsByName[KAIKAS];
    // connector 체크
    if (!connector) return toastError(CONNECTOR_CONFIG);
    // kaikas가 install되어 있지 않은 브라우저로 로그인 시도 시 크롬 웹스토어로 이동
    if ((window?.klaytn?.networkVersion !== KLAY_MAINNET || !window.klaytn) && !isWalletConnect) {
      window.open('https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi');
      return toastError(PROVIDER_ERROR);
    }
    // 체인 매칭 체크
    // const chainId = process.env.REACT_APP_ENVIRONMENT === 'production' ? KLAY_MAINNET : KLAY_TESTNET;
    const chainId = KLAY_MAINNET;
    if (window?.klaytn?.networkVersion !== chainId) return toastError(KLAYTN_UNSUPPORTED_CHAIN);
    // 로그인
    try {
      const accounts = await window.klaytn.enable();
      const account = accounts[0];
      const { signIn } = requestUrl;
      const data = await fetchData(`${signIn}${account}`);
      if (getItem(GET_INFO)) return;
      if (!data) {
        setItem(STORAGE_SESSION, KAIKAS);
        navigate('/connect/register');
        return;
      }
      if (data) {
        const { member_username, member_wallet_type, member_imageurl, member_is_admin, member_network_names } = data;
        const isMember = member_network_names?.includes(KLAYTN);
        setItem(GET_INFO, {
          chain: KLAYTN,
          chainId,
          account,
          username: member_username,
          walletType: member_wallet_type,
          profileImg: member_imageurl,
          admin: member_is_admin && isMember && 1,
        });
        navigate('/');
      }
      return setItem(STORAGE_SESSION, KAIKAS);
    } catch {
      return toastError('Fail on register', 'You must complete the wallet signature.');
    }
  }, [KAIKAS, CONNECTOR_CONFIG, KLAY_MAINNET, isWalletConnect, KLAYTN_UNSUPPORTED_CHAIN, PROVIDER_ERROR, navigate, KLAYTN]);

  //Wallet connect 로그인
  const walletConnect = useCallback(
    async chainId => {
      try {
        setItem(STORAGE_SESSION, METAMASK);
        setItem(WALLET_SESSION, true);
        await walletConnectV2.activate(chainId);
      } catch (e) {
        removeItem(STORAGE_SESSION);
        removeItem(WALLET_SESSION);
      }
    },
    [METAMASK],
  );

  //클립 로그인
  const klipConnect = useCallback(async () => {
    try {
      const bappName = 'UNILAPSE';
      // const successLink = 'https://www.unilapse.com/';
      // const failLink = 'https://www.unilapse.com/connect';
      const { request_key } = await prepare.auth({ bappName });
      const deepLink = getDeepLink(request_key);
      setItem(STORAGE_SESSION, KLIP);
      return { deepLink, request_key };
    } catch (e) {
      removeItem(STORAGE_SESSION);
    }
  }, [KLIP]);

  //로그 아웃
  const disconnect = useCallback(async () => {
    metamaskInjector.resetState();
    walletConnectV2.deactivate();
    setSignedMessage({
      signature: '',
      message: '',
    });
    removeItem(STORAGE_SESSION);
    removeItem(GET_INFO);
    removeItem(WALLET_SESSION);
    navigate('/');
    window.location.reload();
  }, [setSignedMessage]);

  /*
  유저가 로그인 한 상태에서 
  정상적이지 않은 방법(우리의 로그인 로직사용을 제외한 방법)으로 
  다른 연결을 할 시 로그아웃 시키는 옵저버 
  Routes.js 에서 사용됨
  */

  useEffect(() => {
    if (!walletType || !account) return;

    // walletType === 'metamask' 일 경우,
    if (walletType === METAMASK) {
      // production 일 경우, chainId가 Eth Mainnet 아니면서, Polygon Mainnet이 아닐 경우
      if (process.env.REACT_APP_ENVIRONMENT === 'production' && chainId !== ETH_MAINNET && chainId !== POLYGON_MAINNET && chainId !== BSC_MAINNET) {
        // chainId가 Eth Testnet이라면, Eth Mainnet으로 연결 유도
        if (chainId === ETH_TESTNET) {
          return setupETHNetwork(ETH_MAINNET);
        }
        // chainId가 Polygon Testnet이라면, Polygon Mainnet으로 연결 유도
        if (chainId === POLYGON_TESTNET) {
          return setupETHNetwork(POLYGON_MAINNET);
        }
        if (chainId === BSC_TESTNET) {
          return setupETHNetwork(BSC_MAINNET);
        }
      }

      // development 일 경우, chainId가 Eth Mainnet 아니면서, Polygon Mainnet이 아닐 경우
      if (process.env.REACT_APP_ENVIRONMENT === 'development' && chainId !== ETH_TESTNET && chainId !== POLYGON_TESTNET && chainId !== BSC_TESTNET) {
        // chainId가 Eth Mainnet이라면, Eth Testnet으로 연결 유도
        if (chainId === ETH_MAINNET) {
          return setupETHNetwork(ETH_TESTNET);
        }
        // chainId가 Polygon Mainnet이라면, Polygon Testnet으로 연결 유도
        if (chainId === POLYGON_MAINNET) {
          return setupETHNetwork(POLYGON_TESTNET);
        }
        if (chainId === BSC_MAINNET) {
          return setupETHNetwork(BSC_TESTNET);
        }
      }

      // 위의 조건에 포함되지 않는 다른 chainId가 들어온다면 production은 Eth Mainnet, development는 Eth Testnet으로 연결유도
      if (chainId !== ETH_MAINNET && chainId !== ETH_TESTNET && chainId !== POLYGON_MAINNET && chainId !== POLYGON_TESTNET && chainId !== BSC_MAINNET && chainId !== BSC_TESTNET) {
        process.env.REACT_APP_ENVIRONMENT === 'production' ? setupETHNetwork(ETH_MAINNET) : setupETHNetwork(ETH_TESTNET);
      }
    }
  }, [account, chainId, walletType, METAMASK, ETH_MAINNET, ETH_TESTNET, POLYGON_MAINNET, POLYGON_TESTNET, BSC_MAINNET, BSC_TESTNET]);

  const observingWallet = useCallback(() => {
    const userInfo = getItem(GET_INFO);
    const connectedWallet = getItem(STORAGE_SESSION);
    const isWalletConnect = getItem(WALLET_SESSION);
    if (!userInfo) return;
    const { account: signInAccount, chainId: signInChainId, walletType } = userInfo;
    if (!signInAccount || !signInChainId) return;
    if (!userInfo || connectedWallet === KLIP || isWalletConnect) return;
    const exceptionPage = ['create', 'minting'];
    if (connectedWallet === KAIKAS) {
      window.klaytn.on('accountsChanged', () => {
        navigate('/');
        disconnect();
        return;
      });
      window.klaytn.on('networkChanged', () => {
        navigate('/');
        disconnect();
        return;
      });
    }
    if (walletType !== KAIKAS && account !== undefined && account !== signInAccount) {
      navigate('/');
      disconnect();
      toastError('Account changed', 'Looks like you connected to another account. Please switch to the account you logged in for the first time');
      if (exceptionPage.some(page => location.includes(page))) {
        navigate('/');
      }
      return;
    }
    if (walletType !== KAIKAS && account !== undefined && chainId !== signInChainId) {
      navigate('/');
      disconnect();
      toastError('Network changed', 'Looks like you connected to unsupported network. Change network to Mainnet');
      if (exceptionPage.some(page => location.includes(page))) {
        navigate('/');
      }
      return;
    }
  }, [KLIP, KAIKAS, account, chainId, navigate, disconnect, location]);

  return { metamaskConnect, kaikasConnect, walletConnect, disconnect, observingWallet, klipConnect };
};

export default useAuth;
