import { InjectedConnector } from '@web3-react/injected-connector';
import { KaikasConnector } from 'kaikas-connector';
import { WALLET_TYPE } from 'constants/USER_INFO';
const { METAMASK, KAIKAS, KLIP } = WALLET_TYPE;
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const HOST = window.location.host;

export const metamask = new InjectedConnector({
  supportedChainIds: ENVIRONMENT === 'development' ? [5, 80001, 97] : ENVIRONMENT === 'production' && HOST.includes('dev.') ? [5, 80001, 97] : [1, 137, 56],
});

export const kaikas = new KaikasConnector({
  supportedChainIds: [8217, 1001],
});

export const connectorsByName = {
  [METAMASK]: metamask,
  [KAIKAS]: kaikas,
  [KLIP]: 'klip',
};
