export const CHAIN = {
  KLAYTN: 'klaytn',
  ETHEREUM: 'ethereum',
  POLYGON: 'polygon',
  BSC: 'bsc',
};

export const WALLET_TYPE = {
  METAMASK: 'metamask',
  KAIKAS: 'kaikas',
  KLIP: 'klip',
};
