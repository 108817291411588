import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Main = lazy(() => import('pages/Main'));
const Collection = lazy(() => import('pages/Collection'));
const Market = lazy(() => import('pages/Market'));
const ArtDetail = lazy(() => import('pages/ArtDetail'));
const ArtistDetail = lazy(() => import('pages/ArtistDetail'));
const Nav = lazy(() => import('components/Nav'));
const Footer = lazy(() => import('components/Footer'));
const Artist = lazy(() => import('pages/Artist'));
const News = lazy(() => import('pages/News'));
const MyPage = lazy(() => import('pages/Mypage'));
const About = lazy(() => import('pages/About'));
const AccountSetting = lazy(() => import('pages/AccountSetting'));
const Create = lazy(() => import('pages/Create/'));
const CreateCojamNFT = lazy(() => import('pages/CreateCojamNFT'));
const OpenseaUpload = lazy(() => import('pages/OpenseaUpload'));
const Minting = lazy(() => import('pages/Minting'));
const Exhibition = lazy(() => import('pages/Exhibition'));
const ExhibitionDetail = lazy(() => import('pages/ExhibitionDetail'));
const UserBoard = lazy(() => import('pages/UserBoard'));
const UserBoardList = lazy(() => import('pages/UserBoard/list'));
const UserBoardDetail = lazy(() => import('pages/UserBoard/detail'));
const UserBoardUpdate = lazy(() => import('pages/UserBoard/update'));

const Switchs = () => {
  return (
    <>
      <Nav />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/collection/:id" element={<Collection />} />
        <Route path="/market" element={<Market />} />
        <Route path="/art/detail/:id" element={<ArtDetail />} />
        <Route path="/artist/profile/:username" element={<ArtistDetail />} />
        <Route path="/artist" element={<Artist />} />
        <Route path="/about" element={<About />} />
        <Route path="/news" element={<News />} />
        <Route path="/mypage" element={<MyPage />} />
        <Route path="/settings/account" element={<AccountSetting />} />
        <Route path="/create/" element={<Create />} />
        <Route path="/create/unilapse" element={<CreateCojamNFT />} />
        <Route path="/create/opensea" element={<OpenseaUpload />} />
        <Route path="/Minting" element={<Minting />} />
        <Route path="/exhibition" element={<Exhibition />} />
        <Route path="/exhibition/:id" element={<ExhibitionDetail />} />
        <Route path="/user-board" element={<UserBoard />}>
          <Route path="" element={<UserBoardList />} />
          <Route path=":id" element={<UserBoardDetail />} />
          <Route path="update/:id" element={<UserBoardUpdate />} />
        </Route>
      </Routes>
      <Footer />
    </>
  );
};

export default Switchs;
