import { atom , selector} from 'recoil';
import { CHAIN } from 'constants/USER_INFO';
import { getItem, GET_INFO } from 'utils/sessionStorage';
import defaultAudioPreviewImage from 'assets/images/main/nft_default.png';

const { KLAYTN } = CHAIN;
const userInfo = getItem(GET_INFO);
const chain = userInfo?.chain;

// for Sign Up
export const signAtom = atom({
  key: 'signAtom',
  default: {
    signature: '',
    message: '',
  },
});

//for minting lsit query key
export const mintinglistQueryKey = atom({
  key: 'mintinglistQueryKey',
  default: chain === KLAYTN ? 'klaytn-art-list' : 'eth-art-list',
});

export const deListingQueryKey = atom({
  key: 'deListingQueryKey',
  default: 'myListingNfts',
});

//Minting
export const mediaFileAtom = atom({
  key: 'mediaFile',
  default: null,
});
//Minting
export const isVideoAtom = atom({
  key: 'isVideo',
  default: false,
});
//Minting
export const nftDataAtom = atom({
  key: 'nftData',
  default: null,
});

//Minting
export const mintingTxHashAtom = atom({
  key: 'mintingTxHash',
  default: '',
});

//Listing
export const listingTxHashAtom = atom({
  key: 'listingTxHash',
  default: '',
});

//DeListing
export const deListingTxHashAtom = atom({
  key: 'deListingTxHash',
  default: '',
});

//Buying
export const buyTxHashAtom = atom({
  key: 'buyTxHash',
  default: '',
});

//LocalUrl
export const blobUrlAtom = atom({
  key: 'blobUrl',
  default: '',
});

//isProfieUpdate?
export const isProfileUpdate = atom({
  key: 'isProfileUpdate',
  default: false,
});

//nft approve?
export const isNftApprovingAtom = atom({
  key: 'isNftApprove',
  default: false,
});

// CT apporve ?
export const isCTApprovingAtom = atom({
  key: 'isCTApprove',
  default: false,
});

//ETH_TOKEN_ID
export const ethTokenIdAtom = atom({
  key: 'approveLoading',
  default: '',
});

//Reset Create Form
export const resetCreateFormAtom = atom({
  key: 'resetCreateForm',
  default: false,
});

//Delete Nft
export const burnNftAtom = atom({
  key: 'burnNft',
  default: '',
});

//refetch
export const refetchAtom = atom({
  key: 'refetch',
  default: false,
});

//collection single edition
export const selectedEditionAtom = atom({
  key: 'selectedEdition',
  default: 0,
});

//audio background image
export const audioBackgroundImageState = atom({
  key: 'audioBackgroundImageState',
  default: defaultAudioPreviewImage,
});

export const audioBackgroundImageSelector = selector({
  key: 'audioBackgroundImageSelector',
  get: ({ get }) => {
    return get(audioBackgroundImageState);
  },
  set: ({ set }, newValue) => {
    set(audioBackgroundImageState, newValue);
  },
}); 
