import { useCallback } from 'react';
import Logger from 'utils/Logger';

const useApiError = () => {
  const handleError = useCallback(error => {
    Logger.log(error);
  }, []);

  return { handleError };
};

export default useApiError;
