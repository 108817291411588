import sample from 'lodash-es/sample';

export const nodes = ['https://eth-mainnet.alchemyapi.io/v2/JLxqbEj66umGDmHEFEygtdIfDOTjSFPU', 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'];
export const klaytnNodes = ['https://api.baobab.klaytn.net:8651'];
// ['https://eth-mainnet.alchemyapi.io/v2/JLxqbEj66umGDmHEFEygtdIfDOTjSFPU']; //Alchemy rpc
const getNodeUrl = arr => {
  return sample(arr);
};

export default getNodeUrl;
