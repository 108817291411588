export const MOCKUP = {
  guide: 'data/mockUp/guide.json',
};

export const requestUrl = {
  // paged 삭제 -> 어차피 전부 pagenation할 것이기 때문에

  //collection
  collectionDetail: '/collection/detail/',

  //market
  recentCuratedCollections: '/collection/all/editions',

  //art
  artAllPaged: '/art/all?sort=', // Market 목록 리스트
  listing: '/art/all/unlisted?owner=', // 리스팅 리스트 불러오기
  artDetail: '/art/detail/', // Art detail
  artistArts: '/art/artist/all?username=', // artist detial 페이지 최 하단 아티스트의 아트 리스트
  otherArts: '/art/other', // offset limit으로 query로만
  artFeatured: '/art/featured', // Main page 5개 arts
  artistFeatured: '/artist/featured', // Main page artist
  eventHistory: '/art/events', // Tx 히스토리
  updatePrice: '/art/update/price', // 리스팅하기 (가격 업데이트 하기)
  updateNftStatus: '/art/update/list/status', // 거래 완료 후 상태 업데이트
  myListingNfts: '/art/listing', // my Listing NFT 리스트

  //add art
  postArt: 'art/add',
  postOpenSeaArt: '/art/opensea/add',
  postOpenSeaArtCollection: 'admin/collection/arts/add',
  getMetaData: '/art/ipfs',
  uploadNFT: '/art/update/mint', // Minting

  //delete art
  artDelete: '/art/',

  //de-listing
  artDeListing: '/art/listing/cancel',

  //admin art
  // artAllAdmin: '/admin/art/all',
  artKlaytnAdmin: '/art/admin/chain/list?chain=klaytn&owner=',
  artEthAdmin: '/art/admin/chain/list?chain=ethereum&owner=',
  artPolygonAdmin: '/art/admin/chain/list?chain=polygon&owner=',
  artBscAdmin: '/art/admin/chain/list?chain=bsc&owner=',
  artDetailAdmin: '/admin/art/detail/',

  //Member
  signIn: '/user/connect/', // 로그인
  createNewUser: '/user/create', // 회원가입
  checkUsername: '/user/checkuser', // 회원가입 시 username 중복 체크
  myNfts: '/user/mynft', // my NFT 리스트 (path parameter)
  updateProfilePicture: '/user/profile/update', // 프로필 이미지 업로드

  //news
  newsAll: '/news/all',
  newsExternal: '/news/external',
  newsInternal: '/news/internal',
  newsFeatured: '/news/featured',
  newsDetail: '/news/detail/',

  //artist
  artistProfile: '/artist/profile/',

  //wishlist
  wishlistAdd: '/wishlist/add', // wishlist 추가
  wishlistDelete: '/wishlist/delete', // wishlist 삭제
  wishlistAll: '/wishlist/all', // wishlist

  //exhibition
  exhibitionAll: '/exhibition',
  exhibitionType: '/exhibition/type?',
  exhibitionFeaturedType: '/exhibition/featured?isExhibition=',
  exhibitionDetail: '/exhibition/',

  //user board
  postUserBoard: 'board',
  userBoardAll: 'board',
  userBoardView: 'board/view',
  userBoardFeatured: 'board/featured/',
  userBoardDetail: 'board/',
  userBoardSearch: 'board/search',
  userBoardTop: 'board/top',
  userBoardCancel: 'board/cancel',
  userBoardUpdate: 'board',
  userBoardUploadImage: 'board/image/upload',
  userBoardImageList: 'board/images/',
  userBoardImageDelete: 'board/image/delete',
  userBoardDelete: 'board',

  //banner
  banner: '/banner/all', // Main page 최상단 배너

  searchAll: '/search/all', // 검색 리스트
  recentArt: '/recent/art/all', // 최근 본 아트 리스트 pagenation 있음
  recentArtist: '/recent/artist/all', // 최근 본 artist pagenation 없음
  collectionArts: '/collection/detail/',
  postRecentArtist: '/recent/artist/add', // 최근 본 아티스트 추가
  postRecentArt: '/recent/art/add', // 최근 본 아트 추가

  // pagenation적용 될 예정
  artistAll: '/artist/all/', // artist 목록 리스트

  // pagenation적용 될 예정
  collectionAll: '/collection/all', //Main page 아티스트 carousel 슬라이더

  // top, bottom, side banner 내용
  getGlobalState: '/globalurl',

  getPartners: '/partners',
};

export const klipRequestUrl = {
  web: 'https://klipwallet.com/?target=/a2a?request_key=',
  ios: 'kakaotalk://klipwallet/open?url=https://klipwallet.com/?target=/a2a?request_key=',
  android(request_key) {
    let url;
    url = `intent://klipwallet/open?url=https://klipwallet.com/?target=/a2a?request_key=${request_key}#Intent;scheme=kakaotalk;package=com.kakao.talk;end`;
    return url;
  },
};

export const externalGallery = 'https://portal.furioos.com/share/NnmoYRw3tvnQMgmJE';
