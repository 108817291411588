import 'styles/main.scss';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { Web3ReactProvider } from '@web3-react/core';
import { QueryClientProvider, QueryClient } from 'react-query';
import { createBrowserHistory } from 'history';
import { metamaskHooks, metamaskInjector, walletConnectV2, walletConnectV2Hooks } from 'utils/connectors';
import Routes from './Routes';
import useApiError from 'hooks/useApiError';

// window.onload = function () {
//   // localStorage.clear();
// };

const { handleError } = useApiError;

const queryClient = new QueryClient({
  defaultOptions: {
    onError: handleError,
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const connectors = [
  [metamaskInjector, metamaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
];

function App() {
  // Router에서 HistoryRouter로 NavigationGuard를 위해 바꿈 (react-router-dom ^6.4.0)
  return (
    <Web3ReactProvider connectors={connectors}>
      <QueryClientProvider client={queryClient}>
        <HistoryRouter history={createBrowserHistory({ window })}>
          <Routes />
        </HistoryRouter>
      </QueryClientProvider>
    </Web3ReactProvider>
  );
}

export default App;
