import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { notification } from 'antd';
import { toastError } from 'utils/toast';
import { setItem, getItem, WALLET_SESSION, GET_INFO, STORAGE_SESSION } from 'utils/sessionStorage';
import { useReactQuery } from 'hooks/useReactQuery';
import { requestUrl } from 'config';
import CHAIN_LIST from 'constants/chain';
import { CHAIN } from 'constants/USER_INFO';

const useSignIn = () => {
  const { account, chainId } = useWeb3React();
  const navigate = useNavigate();
  const { signIn } = requestUrl;
  const { data, isError, error, isSuccess, refetch } = useReactQuery(`signIn-${account}`, `${signIn}${account}`, { enabled: false });
  const router = useLocation().pathname;
  const location = useLocation();
  const prevPath = location.state?.prevPath;
  const { KLAYTN, ETHEREUM, POLYGON, BSC } = CHAIN;
  const isWalletConnect = getItem(WALLET_SESSION);

  useEffect(() => {
    if (!isError) return;
    toastError('Failed Login', error);
  }, [isError, error]);

  useEffect(() => {
    if (!account || !chainId) return;
    refetch();
  }, [refetch, account, chainId]);

  useEffect(() => {
    if (!isSuccess) return;
    if (!data) {
      navigate('/connect/register');
    }
    if (data) {
      const { member_username, member_wallet_type, member_imageurl, member_is_admin, member_network_names } = data;
      const { KLAY_MAINNET, KLAY_TESTNET, POLYGON_MAINNET, POLYGON_TESTNET, BSC_MAINNET, BSC_TESTNET } = CHAIN_LIST;
      const chain =
        chainId === KLAY_MAINNET || chainId === KLAY_TESTNET
          ? KLAYTN
          : chainId === POLYGON_MAINNET || chainId === POLYGON_TESTNET
          ? POLYGON
          : chainId === BSC_MAINNET || chainId === BSC_TESTNET
          ? BSC
          : ETHEREUM;
      const isMember = member_network_names?.includes(chain);
      console.log(member_network_names);
      setItem(GET_INFO, {
        chain,
        chainId,
        account,
        username: member_username,
        walletType: member_wallet_type,
        profileImg: member_imageurl,
        // admin: member_is_admin && isMember && 1,
        admin: member_is_admin,
      });
      notification.success({
        message: `Connected to Unilapse`,
        description: `${
          isWalletConnect
            ? `[WALLET CONNECT: ${member_wallet_type?.toUpperCase()}]`
            : `[WALLET: ${member_wallet_type?.toUpperCase()}, CHAIN: ${
                chainId === KLAY_MAINNET || chainId === KLAY_TESTNET
                  ? KLAYTN.toUpperCase()
                  : chainId === POLYGON_MAINNET || chainId === POLYGON_TESTNET
                  ? POLYGON.toUpperCase()
                  : chainId === BSC_MAINNET || chainId === BSC_TESTNET
                  ? BSC.toUpperCase()
                  : ETHEREUM.toUpperCase()
              }]`
        }`,
      });
      if (router === `/connect`) {
        if (prevPath) {
          navigate(prevPath);
        } else {
          navigate('/');
        }
      }
    }
  }, [data, navigate, isSuccess, router, prevPath, KLAYTN, POLYGON, BSC, ETHEREUM, chainId, account]);

  return;
};

export default useSignIn;
