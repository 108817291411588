export function getErrorString(e) {
  // Check if the exactly matching code
  const message = ERROR_MESSAGES[e?.message] || ERROR_MESSAGES[e?.reason];
  if (message) {
    return message;
  }

  // Fallback to check if message contains any key
  for (const key in ERROR_MESSAGES) {
    if ((e?.message && e.message.includes(key)) || (e?.reason && e.reason.includes(key))) {
      return ERROR_MESSAGES[key];
    }
  }

  // Fallback to throw a raw error message
  return e?.error?.message || e?.data?.message || e?.message || e?.name || e?.reason || e?.code || e;
}

// const ERROR_MESSAGES = {
//   UNSUPPORTED_CHAIN: 'This chain is currently not supported. Please connect to Ethereum network on your wallet.',
//   PROVIDER_ERROR: 'The wallet extension is not installed. Please install Metamask or Kaikas to continue.',
//   AUTHORIZATION_ERROR: 'Please authorize to access your account.',
//   CONNECTOR_CONFIG: 'The connector config is wrong.',
//   CHANGE_TO_BSC: 'Please change your network to Ethereum and try again.',
//   INVALID_SESSION: 'Invalid session. Please try logging in again.',
//   INVALID_SIGNATURE: 'Invalid session. Please try logging in again.',
//   EXCEEDED_MAX_SUPPLY: 'May supply limit is exceeded. Please enter a smaller amount.',
//   RESERVE_TOKEN_TRANSFER_FAILED: 'You do not have enough balance to complete the transaction.',
//   SLIPPAGE_LIMIT_EXCEEDED:
//     'Your transaction has cancelled because a transaction occurred earlier than yours. Please try again (you may need to increase your slippage tolerance).',
//   'transaction underpriced': 'Transaction failed due to low gas price. Please try with a higher gas price.',
//   'transaction failed':
//     'Your transaction has cancelled due a transaction occurring earlier than yours. Please try again (you may need to increase your slippage tolerance).',
//   INVALID_WEBSITE: 'Invalid website URL. Please enter your own site URL.',
//   INVALID_TOKEN: 'Token not found.',
//   NETWORK_ERROR: 'Please connect to Ethereum network on your wallet.',
//   WATCH_UNSUPPORTED: 'Your wallet does not support watchAsset function.',
//   SERVER_ERROR: 'There is a temporary connection issue with the RPC server we use. Please try again later.',
// };

export const ERROR_MESSAGES_HANDLER = {
  DENIED_SIGN: 'User denied message signature.',
  INVALID_PARAMETER: 'Invalid parameter: Parameter value and type do not match.',
  KLAYTN_UNSUPPORTED_CHAIN: 'This chain is currently not supported. Please connect to Cypress network on your wallet.',
  ETHEREUM_UNSUPPORTED_CHAIN: 'This chain is currently not supported. Please connect to Ethereum network on your wallet.',
  PROVIDER_ERROR: 'The wallet extension is not installed. Please install Metamask or Kaikas to continue.',
  AUTHORIZATION_ERROR: 'Please authorize to access your account.',
  CONNECTOR_CONFIG: 'The connector config is wrong.',
  CHANGE_TO_BSC: 'Please change your network to Ethereum and try again.',
  INVALID_SESSION: 'Invalid session. Please try logging in again.',
  INVALID_SIGNATURE: 'Invalid session. Please try logging in again.',
  EXCEEDED_MAX_SUPPLY: 'May supply limit is exceeded. Please enter a smaller amount.',
  RESERVE_TOKEN_TRANSFER_FAILED: 'You do not have enough balance to complete the transaction.',
  SLIPPAGE_LIMIT_EXCEEDED: 'Your transaction has cancelled because a transaction occurred earlier than yours. Please try again (you may need to increase your slippage tolerance).',
  'transaction underpriced': 'Transaction failed due to low gas price. Please try with a higher gas price.',
  'transaction failed': 'Your transaction has cancelled due a transaction occurring earlier than yours. Please try again (you may need to increase your slippage tolerance).',
  INVALID_WEBSITE: 'Invalid website URL. Please enter your own site URL.',
  INVALID_TOKEN: 'Token not found.',
  NETWORK_ERROR: 'Please connect to Ethereum network on your wallet.',
  WATCH_UNSUPPORTED: 'Your wallet does not support watchAsset function.',
  SERVER_ERROR: 'There is a temporary connection issue with the RPC server we use. Please try again later.',
};

export const ERROR_TITLE = {
  SIGN_ERROR: 'Failed on Sign',
  TX_ERROR: 'Failed on transaction',
};

export const ERROR_MESSAGES = {
  CHECK_SIGN: 'User denied message signature.',
  CHECK_PARAMETER: '유효하지 않은 입력값 입니다. 입력값을 확인해주세요',
  KLAYTN_UNSUPPORTED_CHAIN: 'This chain is currently not supported. Please connect to Cypress network on your wallet.',
  ETHEREUM_UNSUPPORTED_CHAIN: 'This chain is currently not supported. Please connect to Ethereum network on your wallet.',
  PROVIDER_ERROR: 'The wallet extension is not installed. Please install Metamask or Kaikas to continue.',
  AUTHORIZATION_ERROR: 'Please authorize to access your account.',
  CONNECTOR_CONFIG: 'The connector config is wrong.',
  CHANGE_TO_BSC: 'Please change your network to Ethereum and try again.',
  INVALID_SESSION: 'Invalid session. Please try logging in again.',
  INVALID_SIGNATURE: 'Invalid session. Please try logging in again.',
  EXCEEDED_MAX_SUPPLY: 'May supply limit is exceeded. Please enter a smaller amount.',
  RESERVE_TOKEN_TRANSFER_FAILED: 'You do not have enough balance to complete the transaction.',
  SLIPPAGE_LIMIT_EXCEEDED: 'Your transaction has cancelled because a transaction occurred earlier than yours. Please try again (you may need to increase your slippage tolerance).',
  'transaction underpriced': 'Transaction failed due to low gas price. Please try with a higher gas price.',
  'transaction failed': 'Your transaction has cancelled due a transaction occurring earlier than yours. Please try again (you may need to increase your slippage tolerance).',
  INVALID_WEBSITE: 'Invalid website URL. Please enter your own site URL.',
  INVALID_TOKEN: 'Token not found.',
  NETWORK_ERROR: 'Please connect to Ethereum network on your wallet.',
  WATCH_UNSUPPORTED: 'Your wallet does not support watchAsset function.',
  SERVER_ERROR: 'There is a temporary connection issue with the RPC server we use. Please try again later.',
};
