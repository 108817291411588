import { isAndroid, isIOS } from 'react-device-detect';
import { klipRequestUrl } from 'config';

const getDeepLink = request_key => {
  const { web, android, ios } = klipRequestUrl;
  let url;
  if (isAndroid) {
    url = android(request_key);
  }
  if (isIOS) {
    url = `${ios}${request_key}`;
  }
  if (!isAndroid && !isIOS) {
    url = `${web}${request_key}`;
  }
  return url;
};

export default getDeepLink;
