// Set of helper functions to facilitate wallet setup
//configs

import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import CHAIN_LIST from 'constants/chain';
import { nodes } from './getRpcUrl';
import Logger from './Logger';

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupETHNetwork = async overrideChainId => {
  const { ETH_MAINNET, ETH_TESTNET, POLYGON_MAINNET, POLYGON_TESTNET } = CHAIN_LIST;

  const provider = window?.ethereum;
  if (provider) {
    const chainId = overrideChainId || (process.env.REACT_APP_ENVIRONMENT === 'production' ? ETH_MAINNET || POLYGON_MAINNET : ETH_TESTNET || POLYGON_TESTNET);
    let chainName = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'Mainnet' : 'Testnet';
    let blockurl = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'testnet.' : '';

    if (overrideChainId) {
      if (overrideChainId === ETH_MAINNET) {
        chainName = 'Mainnet';
        blockurl = '';
      } else if (overrideChainId === ETH_TESTNET) {
        chainName = 'Testnet';
        blockurl = 'rinkeby.';
      }
    }
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      });
      return true;
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${chainId.toString(16)}`,
                chainName: `Ethereum ${chainName}`,
                nativeCurrency: {
                  name: 'ETH',
                  symbol: 'eth',
                  decimals: 18,
                },
                rpcUrls: nodes,
                blockExplorerUrls: [`https://${blockurl}etherscan.io/`],
              },
            ],
          });
        } catch (error) {
          Logger.log(error);
        }
      } else {
        Logger.log("Can't setup the Ethereum network on metamask because window.ethereum is undefined");
        return false;
      }
    }
  }
};

export const walletconnect = new WalletConnectConnector({
  rpc: {
    1: nodes[0],
    4: nodes[3],
  },
  qrcode: true,
  pollingInterval: 12000,
});

export const resetWalletConnector = connector => {
  if (connector && connector instanceof WalletConnectConnector) {
    connector.walletConnectProvider = undefined;
  }
};
