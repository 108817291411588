import axios from 'axios';
import Logger from './Logger';

const { REACT_APP_BASE_URL, REACT_APP_AUTHORIZATION } = process.env;
const client = axios.create({
  baseURL: REACT_APP_BASE_URL,
  withCredentials: true,
  credentials: 'include',
  // headers: {
  //   'Access-Control-Allow-Origin': '*',
  // },
});

const onRequest = config => {
  const { method, url, data } = config;
  Logger.log('axios ======================>', method, url, data ?? '', config);
  return config;
};

const onResponseSuccess = response => {
  const { url } = response.config;
  const { status } = response;
  Logger.log('axios <======================', status, url, response.data);
  return response;
};

const onResponseRejected = error => {
  Logger.log('axios error', error.config?.url, error, error.response?.data ?? '');
  if (error.config) {
    return Promise.reject(error);
  }
};

const getTokenFromCookie = () => {
  const _cookie = document?.cookie;
  if (!_cookie) return;

  const _tokenArr = _cookie.split(';').filter(value => value.includes('token='));
  if (!_tokenArr.length) return;

  return _tokenArr[0].split('=')[1];
};

client.interceptors.request.use(onRequest);
client.interceptors.response.use(onResponseSuccess, onResponseRejected);

export const request = ({ ...options }) => {
  client.defaults.headers.common.Authorization = REACT_APP_AUTHORIZATION;
  const token = getTokenFromCookie();
  if (token) {
    client.defaults.headers.token = token;
  }
  const onSuccess = response => response;
  const onError = error => error;
  return client(options).then(onSuccess).catch(onError);
};
