// export const GET_INFO = 'user-info';
// export const STORAGE_SESSION = 'connector-session';
// export const KLIP_ACCOUNT = 'klip-info';
// export const WALLET_SESSION = 'wallet-connect';

// export const getItem = session => {
//   return JSON.parse(sessionStorage.getItem(session));
// };

// export const setItem = (session, item) => {
//   return window.sessionStorage.setItem(session, JSON.stringify(item));
// };

// export const removeItem = session => {
//   return window.sessionStorage.removeItem(session);
// };

// export const addItem = (session, item) => {
//   const connectorID = JSON.parse(sessionStorage.getItem(session));
//   const newItem = { ...connectorID, ...item };
//   return window.sessionStorage.setItem(session, JSON.stringify(newItem));
// };


export const GET_INFO = 'user-info';
export const STORAGE_SESSION = 'connector-session';
export const KLIP_ACCOUNT = 'klip-info';
export const WALLET_SESSION = 'wallet-connect';

export const getItem = session => {
  return JSON.parse(localStorage.getItem(session));
};

export const setItem = (session, item) => {
  return localStorage.setItem(session, JSON.stringify(item));
};

export const removeItem = session => {
  return localStorage.removeItem(session);
};

export const addItem = (session, item) => {
  const connectorID = JSON.parse(localStorage.getItem(session));
  const newItem = { ...connectorID, ...item };
  return localStorage.setItem(session, JSON.stringify(newItem));
};

